import React from 'react'
import leftIcon from '../../assets/images/left.png'
import rightIcon from '../../assets/images/right.png'
import Search from '../../assets/images/search.png'
import CreateTask from '../createtask/CreateTask'

const TopHeader = () => {
    return (
        <>

            <div className="top-bar p-2">
                <div className="inner-block py-2 px-4 gy-3 d-flex align-items-center justify-content-between  flex-wrap">
                    <div>
                        <span className='pe-3'><img src={leftIcon} alt="" height={20} /></span>
                        <span><img src={rightIcon} alt="" height={20} /></span>
                        <h3 className="d-inline-block text-white align-middle mb-0 ms-4">March 2023</h3>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                        <div className="search-block d-flex align-items-center">
                            <button className="border-0 bg-transparent pe-3"><img src={Search} alt="" /></button>
                            <input type="text" name="" id="" placeholder="Search Everything..." />
                        </div>
                        {/* <button className="bg-transparent border-0 create-btn" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"> <img src="image/add.png"
                                alt="" /><span className="ps-2">Create</span></button> */}
                        {['end'].map((placement, idx) => (
                            <CreateTask key={idx} placement={placement} name={placement} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopHeader