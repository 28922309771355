import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import { FaHome } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import { Form } from 'react-bootstrap';
import TaskList from '../viewtask/TaskList';

const CreateTask = ({ name, ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button className="bg-transparent border-end-0 border-top-0 border-bottom-0 rounded-0 create-btn" onClick={handleShow}>
                <CiCirclePlus className='f-28' /> Create Task
            </Button>
            <Offcanvas className="create-task" show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="text-white mb-3 px-4">Create Task</h4>
                        <Form.Select className='pickup-op' aria-label="Default select example">
                            <option>Pickup</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </div>
                    <div className="row g-2 px-4">
                        <div className="col-lg-3 col-sm-6">
                            <label className="form-label">LOB</label>
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <label className="form-label">LOB</label>
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <label className="form-label">LOB</label>
                            <input className="form-control" type="text" />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <label className="form-label">LOB</label>
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                    <div className="block-groups p-4 my-4">
                        <Accordion className='p-0 bg-transparent border-0' defaultActiveKey="0">
                            <Accordion.Item className='p-0 bg-transparent border-0 mb-4' eventKey="0">
                                <Accordion.Header className='p-0 bg-transparent border-0'>
                                    <div className="row justify-content-between m-0 gx-5 w-100">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="task-block text-white">
                                                <strong className="pe-4">From</strong>
                                                <span className="f-16">CDI Furniture</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="task-block text-white">
                                                <strong className="pe-4">From</strong>
                                                <span className="f-16">CDI Furniture</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="task-block text-white">
                                                <strong className="pe-4">From</strong>
                                                <span className="f-16">CDI Furniture</span>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="row gx-5 gy-4 justify-content-between ps-4">
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Recipient Name</label>
                                            <input className="form-control" type="text" placeholder='John Deo' />
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Recipient Name</label>
                                            <input className="form-control" type="text" placeholder='John Deo' />
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Recipient Name</label>
                                            <input className="form-control" type="text" placeholder='John Deo' />
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Address</label>
                                            <div className="input-icons position-relative">
                                                <span className='position-absolute'>
                                                    <FaHome />
                                                </span>
                                                <input className="form-control" type="text" placeholder='John Deo' />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Address</label>
                                            <div className="input-icons position-relative">
                                                <span className='position-absolute'>
                                                    <FaHome />
                                                </span>
                                                <input className="form-control" type="text" placeholder='John Deo' />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Address</label>
                                            <div className="input-icons position-relative">
                                                <span className='position-absolute'>
                                                    <FaHome />
                                                </span>
                                                <input className="form-control" type="text" placeholder='John Deo' />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="row g-2">
                                                <div className="col-sm-6">
                                                    <label className="form-label">Recipient Name</label>
                                                    <input className="form-control" type="text" placeholder='John Deo' />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label">Recipient Name</label>
                                                    <input className="form-control" type="text" placeholder='John Deo' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="row g-2">
                                                <div className="col-sm-6">
                                                    <label className="form-label">Recipient Name</label>
                                                    <input className="form-control" type="text" placeholder='John Deo' />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label">Recipient Name</label>
                                                    <input className="form-control" type="text" placeholder='John Deo' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="row g-2">
                                                <div className="col-sm-6">
                                                    <label className="form-label">Recipient Name</label>
                                                    <input className="form-control" type="text" placeholder='John Deo' />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label">Recipient Name</label>
                                                    <input className="form-control" type="text" placeholder='John Deo' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Recipient Name</label>
                                            <input className="form-control" type="text" placeholder='John Deo' />
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Recipient Name</label>
                                            <input className="form-control" type="text" placeholder='John Deo' />
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Recipient Name</label>
                                            <input className="form-control" type="text" placeholder='John Deo' />
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <input className="switch" id="fob" type="checkbox" />
                                            <label className='d-block' htmlFor="fob">
                                                <text className='text-white'>FOB</text>
                                                <span className='position-absolute'></span>
                                            </label>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <input className="switch" id="fob2" type="checkbox" />
                                            <label className='d-block' htmlFor="fob2">
                                                <text className='text-white'>FOB</text>
                                                <span className='position-absolute'></span>
                                            </label>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <input className="switch" id="fob3" type="checkbox" />
                                            <label className='d-block' htmlFor="fob3">
                                                <text className='text-white'>FOB</text>
                                                <span className='position-absolute'></span>
                                            </label>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='p-0 bg-transparent border-0 mb-4' eventKey="1">
                                <Accordion.Header className='p-0 bg-transparent border-0'>
                                    <div className="row justify-content-between m-0 gx-5 w-100">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="task-block text-white">
                                                <strong className="pe-4">From</strong>
                                                <span className="f-16">CDI Furniture</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="task-block text-white">
                                                <strong className="pe-4">From</strong>
                                                <span className="f-16">CDI Furniture</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="task-block text-white">
                                                <strong className="pe-4">From</strong>
                                                <span className="f-16">CDI Furniture</span>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>

                                    <div className="row gx-5 gy-4 justify-content-between ps-4">
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="form-label">Name</label>
                                                    <input className="form-control" type="text" placeholder='John Deo' />
                                                </div>
                                                <div className="row g-2">
                                                    <div className="col-sm-6">
                                                        <label className="form-label">Traler No</label>
                                                        <input className="form-control" type="text" placeholder='John Deo' />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className="form-label">Seal No</label>
                                                        <input className="form-control" type="text" placeholder='John Deo' />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className="form-label">SCAC</label>
                                                        <input className="form-control" type="text" placeholder='John Deo' />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className="form-label">Pro No</label>
                                                        <input className="form-control" type="text" placeholder='John Deo' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="row gy-3 mt-2">

                                                <div className="col-12">
                                                    <input className="switch" id="fob4" type="checkbox" />
                                                    <label className='d-block' htmlFor="fob4">
                                                        <text className='text-white'>FOB</text>
                                                        <span className='position-absolute'></span>
                                                    </label>
                                                </div>
                                                <div className="col-12">
                                                    <input className="switch" id="fob5" type="checkbox" />
                                                    <label className='d-block' htmlFor="fob5">
                                                        <text className='text-white'>FOB</text>
                                                        <span className='position-absolute'></span>
                                                    </label>
                                                </div>
                                                <div className="col-12">
                                                    <input className="switch" id="fob6" type="checkbox" />
                                                    <label className='d-block' htmlFor="fob6">
                                                        <text className='text-white'>FOB</text>
                                                        <span className='position-absolute'></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <label className="form-label">Recipient Name</label>
                                            <textarea className="form-control" name="" id="" cols="20" rows="8"></textarea>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <h4 className="text-white mb-3 px-4">Customer Order Informations</h4>
                    <div className="table-responsive customer-data">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>Customer Order No</th>
                                    <th>No. Package</th>
                                    <th>Weight</th>
                                    <th>Pallet/Slip</th>
                                    <th>Additional Shipper Info</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button className='add-line bg-transparent border-0 ps-4 mt-2'>
                        <FaPlusCircle />
                        <span className='ps-2 align-middle'>Add lines</span>
                    </button>
                    <h4 className="text-white mb-3 px-4 mt-4">Carrier Informations</h4>
                    <div className="table-responsive customer-data">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>HU. Quantity</th>
                                    <th>HU. Type</th>
                                    <th>Package Quantity</th>
                                    <th>Package Type</th>
                                    <th>Weight</th>
                                    <th>Is Hazardous</th>
                                    <th>Commodity Description</th>
                                    <th>NMFC No</th>
                                    <th>Class</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button className='add-line bg-transparent border-0 ps-4 mt-2'>
                        <FaPlusCircle />
                        <span className='ps-2 align-middle'>Add lines</span>
                    </button>
                    <div className=" p-4">
                        <div className="row justify-content-between gx-5 gy-4">
                            <div className="col-lg-4 col-md-6">
                                <div className="task-block text-white">
                                    <strong className="pe-4">Other Informations</strong>
                                    <span className="f-16"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between p-4">
                        <button className='bg-transparent border-0 text-white'>Cancel</button>
                        {['end'].map((placement, idx) => (
                            <TaskList key={idx} placement={placement} name={placement} />
                        ))}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default CreateTask