import React from 'react'

const DayDate = () => {
    return (
        <>
            <div class="day-top text-center">
                <label class="f-14 text-uppercase text-white opacity-5">Mon</label>
                <h2 class="text-white mt-1">24</h2>
            </div>
        </>
    )
}

export default DayDate