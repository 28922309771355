import React from 'react'
import DayDate from '../date/DayDate'
import DayTask from '../dayTask/DayTask'

const DateTask = () => {
  return (
    <>
        <div className="day-block">
            <DayDate />
            <div className="divider-line my-3 line-one"></div>
            <DayTask />
        </div>
    </>
  )
}

export default DateTask