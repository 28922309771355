import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/layput/Layout';

function App() {
  return (
    <>
      <Layout />
    </>
  );
}

export default App;
