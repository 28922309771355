import React from 'react'
import LeftSidebar from '../sidebar/LeftSidebar'
import TopHeader from '../topheader/TopHeader'
import Dashboard from '../../pages/dashboard/Dashboard'

const Layout = () => {
    return (
        <>
            <div className="d-flex align-items-start">
                <div className="left-sidebar py-4 text-center">
                    <LeftSidebar />
                </div>
                <main className='main-block'>
                    <div class="container-fluid p-3">
                        <TopHeader />
                        <div className="over-scroll">
                            <div className="row">
                                <div className="col-12 mt-4">
                                    <Dashboard />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Layout