import React from 'react'
import Logo from '../../assets/images/logo.png'
import icon1 from '../../assets/images/icon1.png'
import icon2 from '../../assets/images/icon2.png'
import icon4 from '../../assets/images/icon4.png'
import icon5 from '../../assets/images/icon5.png'
import icon6 from '../../assets/images/icon6.png'
import { LuBell } from "react-icons/lu";

const LeftSidebar = () => {
    return (
        <>
            <div>
                <a className="mx-auto" href="#"><img src={Logo} alt="" height={50} /></a>
                <ul className="main-menu p-0 mb-0 mt-4 list-none text-center">
                    <li><span><img src={icon1} alt="" /></span></li>
                    <li className="active"><span><img src={icon2} alt="" /></span></li>
                    <li><span><LuBell className='f-28 text-white' /></span></li>
                </ul>
            </div>
            <div className="w-100">
                <ul className="bottom-menu p-0 mb-3 list-none text-center">
                    <li className="first-option"><span><img src={icon4} alt="" /></span></li>
                    <li className="my-3"><span><img src={icon5} alt="" /></span></li>
                    <li><span><img src={icon6} alt="" /></span></li>
                </ul>
                <div className="user-icon">
                    <img src="" alt="" />
                </div>
            </div>
        </>
    )
}

export default LeftSidebar