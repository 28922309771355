import React from 'react'
import Filter from '../../assets/images/filter.png'
import Dropdown from 'react-bootstrap/Dropdown';

const DayTask = () => {
    return (
        <>
            <div className="filter text-end dropdown">
                <Dropdown>
                    <Dropdown.Toggle className='filter-btn bg-transparent border-0 mb-3 p-0' id="dropdown-basic">
                        <img src={Filter} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu">
                        <ul className="list-none ps-4 mb-3 top-list pb-3">
                            <li className="f-18">Sort by</li>
                            <li>
                                <div className="form-group">
                                    <input type="checkbox" id="task" />
                                    <label for="task" data-bs-toggle="offcanvas" data-bs-target="#tasks"
                                        aria-controls="tasks">Tasks</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="checkbox" id="mission" />
                                    <label for="mission">Mission</label>
                                </div>
                            </li>
                        </ul>
                        <ul className="list-none ps-4 mb-0">
                            <li className="f-18">Filter by</li>
                            <li>
                                <div className="form-group">
                                    <input type="checkbox" id="Un" />
                                    <label for="Un">Unassigned</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="checkbox" id="assi" />
                                    <label for="assi">Assigned</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="checkbox" id="on" />
                                    <label for="on">On Going</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="checkbox" id="delay" />
                                    <label for="delay">Delayed</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="checkbox" id="succ" />
                                    <label for="succ">Succeeded</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="checkbox" id="fail" />
                                    <label for="fail">Failed</label>
                                </div>
                            </li>
                        </ul>
                    </Dropdown.Menu>
                </Dropdown>
                <div >

                </div>
            </div>
            <div className="task-box py-3 px-4">
                <div className="row gy-3">
                    <div className="col-12">
                        <div className="mission-box">
                            <div className="px-2 d-flex align-items-center justify-content-between flex-wrap">
                                <h3 className="f-24 text-white">Mission 1</h3>
                                <div>
                                    <div className="agent-icon d-inline-block me-3 align-middle">
                                        <img className="object-cover" src="" alt="" />
                                    </div>
                                    <label className="mb-0 text-uppercase align-middle text-white opacity-5">Nylah Drew</label>
                                </div>
                            </div>
                            <ul
                                className="task-details d-flex align-items-center justify-content-around list-none px-0 pt-2 mt-2 mb-0">
                                <li>5 Tasks</li>
                                <li>6 Hours</li>
                                <li>6.5 m3</li>
                                <li>65 km</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mission-box">
                            <div className="px-2 d-flex align-items-center justify-content-between flex-wrap">
                                <h3 className="f-24 text-white">Mission 1</h3>
                                <div>
                                    <div className="agent-icon d-inline-block me-3 align-middle">
                                        <img className="object-cover" src="" alt="" />
                                    </div>
                                    <label className="mb-0 text-uppercase align-middle text-white opacity-5">Nylah Drew</label>
                                </div>
                            </div>
                            <ul
                                className="task-details d-flex align-items-center justify-content-around list-none px-0 pt-2 mt-2 mb-0">
                                <li>5 Tasks</li>
                                <li>6 Hours</li>
                                <li>6.5 m3</li>
                                <li>65 km</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mission-box">
                            <div className="px-2 d-flex align-items-center justify-content-between flex-wrap">
                                <h3 className="f-24 text-white">Mission 1</h3>
                                <div>
                                    <div className="agent-icon d-inline-block me-3 align-middle">
                                        <img className="object-cover" src="" alt="" />
                                    </div>
                                    <label className="mb-0 text-uppercase align-middle text-white opacity-5">Nylah Drew</label>
                                </div>
                            </div>
                            <ul
                                className="task-details d-flex align-items-center justify-content-around list-none px-0 pt-2 mt-2 mb-0">
                                <li>5 Tasks</li>
                                <li>6 Hours</li>
                                <li>6.5 m3</li>
                                <li>65 km</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mission-box">
                            <div className="px-2 d-flex align-items-center justify-content-between flex-wrap">
                                <h3 className="f-24 text-white">Mission 1</h3>
                                <div>
                                    <div className="agent-icon d-inline-block me-3 align-middle">
                                        <img className="object-cover" src="" alt="" />
                                    </div>
                                    <label className="mb-0 text-uppercase align-middle text-white opacity-5">Nylah Drew</label>
                                </div>
                            </div>
                            <ul
                                className="task-details d-flex align-items-center justify-content-around list-none px-0 pt-2 mt-2 mb-0">
                                <li>5 Tasks</li>
                                <li>6 Hours</li>
                                <li>6.5 m3</li>
                                <li>65 km</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mission-box">
                            <div className="px-2 d-flex align-items-center justify-content-between flex-wrap">
                                <h3 className="f-24 text-white">Mission 1</h3>
                                <div>
                                    <div className="agent-icon d-inline-block me-3 align-middle">
                                        <img className="object-cover" src="" alt="" />
                                    </div>
                                    <label className="mb-0 text-uppercase align-middle text-white opacity-5">Nylah Drew</label>
                                </div>
                            </div>
                            <ul
                                className="task-details d-flex align-items-center justify-content-around list-none px-0 pt-2 mt-2 mb-0">
                                <li>5 Tasks</li>
                                <li>6 Hours</li>
                                <li>6.5 m3</li>
                                <li>65 km</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DayTask