import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { GrNext } from "react-icons/gr";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Truck from '../../assets/images/trk.png'
import Search from '../../assets/images/search.png'
import Filter from '../../assets/images/filter.png'
import { MdNavigateNext } from "react-icons/md";

const TaskList = ({ ...props }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button className="task-btn me-2" onClick={handleShow}>
                Create Task <GrNext />
            </Button>
            <Offcanvas className="task-list" show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    <Tabs
                        defaultActiveKey="fleet"
                        id="create_task"
                        className="mb-3 assets-block"
                    >
                        <Tab className='mt-4' eventKey="fleet" title="Fleet">

                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="text-white mb-3 px-3">Asset Availabilities</h4>
                            </div>
                            <Tabs
                                defaultActiveKey="active"
                                id="inner_tabs"
                                className="mb-3"
                            >
                                <Tab eventKey="all" title="All (21)">
                                    Tab content for Home
                                </Tab>
                                <Tab eventKey="active" title="Active">
                                    <div className="p-3">
                                        <div className="asset-block">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h3 className="f-18 text-white">Trailer T928</h3>
                                                <div className="w-50">
                                                    <img className="asset-img position-relative img-fluid" src={Truck} alt="" />
                                                </div>
                                            </div>
                                            <div className="row g-0">
                                                <div className="col-md-6 pe-3 mt-4">
                                                    <ul className="trailer-tags d-flex align-items-center justify-content-between p-0 m-0 list-none">
                                                        <li>Hale Traler 53'</li>
                                                        <li>Dry Van Trailer</li>
                                                        <li>L 53' x W101" x H110"</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 ps-3 mt-4">
                                                    <ul className="trailer-tags d-flex align-items-center justify-content-between p-0 m-0 list-none">
                                                        <li>1300 Lbs / 45,000Lbs</li>
                                                        <li>4000 / 4000 cu.ft.</li>
                                                        <li>24 / 28 Pallets</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="asset-block">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h3 className="f-18 text-white">Trailer T928</h3>
                                                <div className="w-50">
                                                    <img className="asset-img position-relative img-fluid" src={Truck} alt="" />
                                                </div>
                                            </div>
                                            <div className="row g-0">
                                                <div className="col-md-6 pe-3 mt-4">
                                                    <ul className="trailer-tags d-flex align-items-center justify-content-between p-0 m-0 list-none">
                                                        <li>Hale Traler 53'</li>
                                                        <li>Dry Van Trailer</li>
                                                        <li>L 53' x W101" x H110"</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 ps-3 mt-4">
                                                    <ul className="trailer-tags d-flex align-items-center justify-content-between p-0 m-0 list-none">
                                                        <li>1300 Lbs / 45,000Lbs</li>
                                                        <li>4000 / 4000 cu.ft.</li>
                                                        <li>24 / 28 Pallets</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="asset-block">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h3 className="f-18 text-white">Trailer T928</h3>
                                                <div className="w-50">
                                                    <img className="asset-img position-relative img-fluid" src={Truck} alt="" />
                                                </div>
                                            </div>
                                            <div className="row g-0">
                                                <div className="col-md-6 pe-3 mt-4">
                                                    <ul className="trailer-tags d-flex align-items-center justify-content-between p-0 m-0 list-none">
                                                        <li>Hale Traler 53'</li>
                                                        <li>Dry Van Trailer</li>
                                                        <li>L 53' x W101" x H110"</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 ps-3 mt-4">
                                                    <ul className="trailer-tags d-flex align-items-center justify-content-between p-0 m-0 list-none">
                                                        <li>1300 Lbs / 45,000Lbs</li>
                                                        <li>4000 / 4000 cu.ft.</li>
                                                        <li>24 / 28 Pallets</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="inactive" title="Inactive">
                                    Tab content for Contact
                                </Tab>
                            </Tabs>
                        </Tab>
                        <Tab eventKey="mission" title="Mission">
                            <div className="p-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="text-white mb-0">Mission</h4>
                                    <div className="d-flex align-items-center">
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Upcoming</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <div className="search-block d-flex mx-3">
                                            <button className="border-0 bg-transparent pe-3">
                                                <img src={Search} alt="" /></button>
                                            <input type="text" name="" id="" placeholder="Search Everything..." />
                                        </div>
                                        <button className="filter-btn m-0 border-0"><img src={Filter} alt="" /></button>
                                    </div>
                                </div>

                                <div className="table-responsive common-table mt-4">
                                    <table className="w-100" cellspacing="15">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span className="f-18 fw-600 text-white mb-0 ps-4">Abitibi</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        className="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span className="f-18 fw-600 text-white mb-0 ps-4">Abitibi</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        className="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span className="f-18 fw-600 text-white mb-0 ps-4">Bas-Saint-Laurent</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        className="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span className="f-18 fw-600 text-white mb-0 ps-4">Sequency-Lac-Saint-Jean</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        className="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span className="f-18 fw-600 text-white mb-0 ps-4">Capitale-Nationale</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        className="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span className="f-18 fw-600 text-white mb-0 ps-4">Mauricie</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        className="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span className="f-18 fw-600 text-white mb-0 ps-4">Estrie</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        className="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span className="f-18 fw-600 text-white mb-0 ps-4">Montreal</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        className="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tasks" title="Tasks">
                            <div className="p-3">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="text-white">Tasks</h4>
                                    <div class="d-flex align-items-center">
                                        <select class="form-select" aria-label="Default select example">
                                            <option selected>Upcoming</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <div class="search-block d-flex mx-3">
                                            <button class="border-0 bg-transparent pe-3"><img src={Search} alt="" /></button>
                                            <input type="text" name="" id="" placeholder="Search Everything..." />
                                        </div>
                                        <button class="filter-btn m-0 border-0"><img src={Filter} alt="" /></button>
                                    </div>
                                </div>

                                <div class="table-responsive common-table mt-4">
                                    <table class="w-100" cellspacing="15">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <div class="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span class="f-18 fw-600 text-white mb-0 ps-4">Abitibi</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        class="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div class="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span class="f-18 fw-600 text-white mb-0 ps-4">Abitibi</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        class="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div class="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span class="f-18 fw-600 text-white mb-0 ps-4">Bas-Saint-Laurent</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        class="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div class="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span class="f-18 fw-600 text-white mb-0 ps-4">Sequency-Lac-Saint-Jean</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        class="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div class="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span class="f-18 fw-600 text-white mb-0 ps-4">Capitale-Nationale</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        class="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div class="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span class="f-18 fw-600 text-white mb-0 ps-4">Mauricie</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        class="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div class="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span class="f-18 fw-600 text-white mb-0 ps-4">Estrie</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        class="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div class="d-flex align-items-center">
                                                        <MdNavigateNext className='f-28 text-white'  />
                                                        <span class="f-18 fw-600 text-white mb-0 ps-4">Montreal</span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <ul
                                                        class="f-16 fw-600 d-flex align-items-center justify-content-between p-0 mb-0 list-none text-white">
                                                        <li>All (27)</li>
                                                        <li>12 Pickup</li>
                                                        <li>4 Dropoff</li>
                                                        <li>6 Stop</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default TaskList