import React from 'react'
import TaskSlider from '../../components/Taskslider/TaskSlider'
import Map from '../../assets/images/map.png'

const Dashboard = () => {
    return (
        <>
            <TaskSlider />
            <div className="mt-4">
                <img className='img-fluid' src={Map} alt="" />
            </div>
        </>
    )
}

export default Dashboard