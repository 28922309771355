import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import DateTask from '../datetask/DateTask';

const TaskSlider = () => {
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <Slider className='task-slider' {...settings}>
                <div>
                    <DateTask />
                </div>
                <div>
                    <DateTask />
                </div>
                <div>
                    <DateTask />
                </div>
                <div>
                    <DateTask />
                </div>
                <div>
                    <DateTask />
                </div>
                <div>
                    <DateTask />
                </div>
                <div>
                    <DateTask />
                </div>
                <div>
                    <DateTask />
                </div>
                <div>
                    <DateTask />
                </div>
            </Slider>
        </>
    )
}

export default TaskSlider